const apiUrl = process.env.REACT_APP_API_URL;

const GET_WAITTIME = 'waitlist/GET_WAITTIME';
const JOIN_WAITLIST = 'waitlist/JOIN_WAITLIST';
const GET_CURRENT_WAITLIST = 'waitlist/GET_CURRENT_WAITLIST';

const getWaitTime = (waitTime) => ({
    type: GET_WAITTIME,
    waitTime
});

const joinWaitlist = (waitlist) => ({
    type: JOIN_WAITLIST,
    waitlist
});

const getCurrentWaitlist = (waitlist) => ({
    type: GET_CURRENT_WAITLIST,
    waitlist
});


export const getWaitTimeThunk = (restaurant_id) => async (dispatch) => {
    const response = await fetch(`${apiUrl}/api/waitlists/waittime/${restaurant_id}`);

    if (response.ok) {
        const data = await response.json();
        dispatch(getWaitTime(data));
        return data;
    }
};

export const joinWaitListThunk = (restaurant_id, name, phone, party_size) => async (dispatch) => {
    const response = await fetch(`${apiUrl}/api/waitlists/join/${restaurant_id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, phone, party_size })
    });

    if (response.ok) {
        const data = await response.json();
        dispatch(joinWaitlist(data));
        return data;
    }
}

export const getCurrentWaitlistThunk = (restaurant_id, waitlistId) => async (dispatch) => {
    const response = await fetch(`${apiUrl}/api/waitlists/${restaurant_id}/${waitlistId}`);

    if (response.ok) {
        const data = await response.json();
        dispatch(getCurrentWaitlist(data));
        return data;
    }
}



const initialState = {}

const waitlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WAITTIME:
            return {
                ...state,
                waitTime: action.waitTime
            };
        case JOIN_WAITLIST:
            return {
                ...state,
                waitlist: action.waitlist
            }
        case GET_CURRENT_WAITLIST:
            return {
                ...state,
                currentWaitList: action.waitlist
            }
        default:
            return state;
    }
}

export default waitlistReducer;
