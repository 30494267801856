import React, { useState } from 'react';
import Header from '../components/Header';
import lunchMenu from '../assets/images/gallery/lunch1.png';
import dinnerMenu1 from '../assets/images/gallery/dinner1.png';
import dinnerMenu2 from '../assets/images/gallery/dinner2.png';
import dinnerMenu3 from '../assets/images/gallery/dinner3.png';
import dinnerMenu4 from '../assets/images/gallery/dinner4.png';
import dinnerMenu6 from '../assets/images/gallery/dinner6.png';
import drinksMenu from '../assets/images/gallery/drink1.png';


const ImageMenuComponent = () => {
  const [selectedMenu, setSelectedMenu] = useState('lunch');
  
  const menus = {
    lunch: [
      lunchMenu
    ],
    dinner: [
      dinnerMenu1,
      dinnerMenu2,
      dinnerMenu3,
      dinnerMenu4,
      dinnerMenu6
    ],
    drinks: [
      drinksMenu
    ]
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Header />

      <div style={{ marginTop:'100px'}}>
        </div>
      <div className="container mt-5 pb-5" > {/* Added padding-bottom here */}
        <div className="d-flex flex-wrap justify-content-center">
          {menus[selectedMenu].map((image, index) => (
            <div key={index} className="mb-4">
              <img src={image} alt={`${selectedMenu} ${index + 1}`} className="img-fluid rounded" />
            </div>
          ))}
        </div>
      </div>
      <div className="menu-footer1">
        <div
          className={`menu-item1 ${selectedMenu === 'lunch' ? 'active' : ''}`}
          onClick={() => handleMenuClick('lunch')}
        >
          Lunch Special
        </div>
        <div
          className={`menu-item1 ${selectedMenu === 'dinner' ? 'active' : ''}`}
          onClick={() => handleMenuClick('dinner')}
        >
          Main Menu
        </div>
        <div
          className={`menu-item1 ${selectedMenu === 'drinks' ? 'active' : ''}`}
          onClick={() => handleMenuClick('drinks')}
        >
          Drink & Dessert
        </div>
      </div>
    </>
  );
};

export default ImageMenuComponent;
