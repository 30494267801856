import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap"
import { Context } from "../context/AppContext"
import { useContext, useState } from "react"
import pop1 from '../assets/images/pop5.png'
import pop2 from '../assets/images/pop2.png'
import pop3 from '../assets/images/pop3.png'
import pop4 from '../assets/images/pop4.png'

const Home2OurBlog = () => {
  const navigate = useNavigate();
  const [modal, showModal] = useState(false)
  const { setRestaurantId} = useContext(Context)

  const handleOpenModal = () => {
    showModal(true)
  }

  const handleToOrder = (id) => { 
    setRestaurantId(id)
    navigate("/online-order")
  }

  return (
    <section className="content-inner overflow-hidden petal-back" >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 image-grid">
            <img src={pop3} />
            <img src={pop2} />
            <img src={pop4} />
            <img  src={pop1} />
          
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp text-content">
            <h2 className=" text-center text-white">A CHINESE ​FLING</h2>
            <p className="text-white">
              Petals of a Peony was born from a love of all ​things Chengdu, Sichuan Province. 
              Our ​founder spent three years in Chengdu and fell ​in love with the city and the food. 
              She brought ​to America everything she loved - the flavors, ​the feelings, the family-style restaurants - so ​that you can get a taste of Sichuan, too.
            </p>
            <a className="text-bold text-black" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+19017078886" 
             >Cordova:  (901)-707-8886</a>
            <br />
            <a className="text-bold text-black" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+19012076680" 
             >Overton: (901)-207-6680 </a>
            <br />

            <div className="text-center d-flex">
            <button className="btn btn-primary btn-md shadow-primary m-r30  w-100" onClick={handleOpenModal}>Order Now</button>
            {/* <button className="btn btn-primary btn-md shadow-primary m-r30  w-100" onClick={() => navigate('/join-waitlist')}>Waitlist</button> */}
              </div>
          </div>
        </div>
      </div>

      <Modal show={modal} onHide={() => showModal(false)} centered >
        <Modal.Header closeButton>
          <Modal.Title>Choose a location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row "> 
            <div className="col-md-6 text-center">
              <p className="text-center">
               Cordova Loaction
                <br />
                1250 N Germantown Pkwy Cordova, TN 38016
                <br />
                (901)-707-8886
                </p>
              <div
                onClick={() => handleToOrder(7)}
                className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
              >
                <span>Order Here</span>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <p className="text-center">
              Overton Square Location
                <br />
                2110 Madison Ave, Memphis, TN 38104
                <br />
                (901)-207-6680
                </p>
              <div
                onClick={() => handleToOrder(11)}
                className="btn btn-primary btn-md shadow-primary btn-hover-1"
              >
                <span>Order Here</span>
              </div>
            </div>
              
          </div>

        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Home2OurBlog;
