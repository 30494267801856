import pop1 from '../assets/images/pop11.jpg'

const ContactSection = () => {
  return (
    <section className="contact-section d-flex h-100">
      <div className="container">
        <div className="row h-100">
          <div className="col-lg-6 ">
            <img 
              src={pop1} 
              alt="Restaurant Dish" 
              className="img-fluid w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <div className="col-lg-6 d-flex flex-column  p-5">
            <h2 className="title text-center mb-4">SEE YOU SOON</h2>
            <p className="contact-info">
              <strong>PHONE NUMBER</strong><br />
              Cordva - (901)-707-8886
              <br />
Overton - (901)-207-6680

            </p>
            <p className="contact-info">
              <strong>EMAIL ADDRESS</strong><br />
              petalsofapeony65@gmail.com
            </p>
            <p className="contact-info">
              <strong>RESTAURANT</strong><br />
              1250 N Germantown Pkwy #105, Cordova, TN
              <br /> 
              2110 Madison Ave, Memphis, TN 38104
            </p>
            <p className="mt-4 text-center text-orange">
              WE TRY TO MAKE OUR RESTAURANT AS AUTHENTIC AS POSSIBLE, SO OUR CUSTOMERS WILL HAVE THE SAME EXPERIENCE LIKE EATING IN THE FINEST SICHUAN RESTAURANT IN CHINA WITHOUT STEPPING ON A PLANE.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
