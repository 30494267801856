import { Home2TestimonialArr } from "../elements/JsonData";

const Home2Testimonial = () => {
  return (
    <div className="petal-test-back">
    <div className="container ">
      <h2 className="title text-center mb-4 text-white p-4">LOVE FOR PETALS</h2>
      <div className="row">
        {Home2TestimonialArr.map(({title, text, name, position }, ind) => (
          <div className="col-lg-4 col-md-6 mb-4 p-2" key={ind}>
            <div className=" testimonial-card h-100">
              <div className="card-body bg-black br-0">
                <h5 className="card-title text-center text-white">{title}</h5>
                <p className="card-text text-white">
                  {text}
                </p>
                <div className="testimonial-info d-flex align-items-center mt-3 text-white">
                  <div>
                    <h5 className="testimonial-name mb-0 text-white">{name}</h5>
                    <span className="testimonial-position">{position}</span>
                  </div>
                  <i className="flaticon-right-quote quote ms-auto"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Home2Testimonial;
