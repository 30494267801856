import { useContext } from "react";
import { Context } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

function OrderStore() {
  const navigate = useNavigate();
  const { setRestaurantId } = useContext(Context);

  const handleToOrder = (id) => {
    setRestaurantId(id);
    navigate("/online-order");
  };

  return (
    <div className="bg-white text-dark py-5">
      <div className="container">
        <h2 className="text-center mb-4">Our Locations</h2>
        <div className="row justify-content-center ">
          <div className="col-md-5 mb-4 ">
            <div className="  custom-location-border  ">
              <div className="card-body text-center  mt-3">
                <h5 className="card-title p-3 ">Cordova Loaction</h5>
                <p className="card-text mt-2">
                  1250 N Germantown Pkwy
                  <br />
                  Cordova, TN 38016
                  <br />
                  (901)-707-8886
                </p>
                <button
                  onClick={() => handleToOrder(7)}
                  className="btn btn-primary mb-3"
                >
                  Order Here
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-5 mb-4">
            <div className=" custom-location-border">
              <div className="card-body text-center mt-3">
                <h5 className="card-title p-3">Overton Square Location</h5>
                <p className="card-text mt-2">
                  2110 Madison Ave,
                  <br />
                  Memphis, TN 38104
                  <br />
                  (901)-207-6680
                </p>
                <button
                  onClick={() => handleToOrder(11)}
                  className="btn btn-primary mb-3"
                >
                  Order Here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderStore;
