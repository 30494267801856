import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { deleteItemThunk, getCartThunk, placeOrderThunkPayInStore } from "../store/cart";
import Header from "../components/Header.js";
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import EditCartModal from "../elements/EditCartModal.js";
import { Context } from "../context/AppContext";
import Lottie from "react-lottie-player";
import loader from "../assets/json/loader.json";
import { isStoreOpen, showTimeError, checkIfLunchTime } from "../Utilts/helper";
import Swal from "sweetalert2";
import { getOnlineStatusThunk } from "../store/restaurants";
import emptyCartLottie from "../assets/json/emptycart.json"
import CheckoutApp from "../elements/StripeCheckoutApp.js";
import { checkCouponThunk, clearCoupon } from "../store/coupons.js";
import EditFreeItemModal from "../elements/EditFreeItemModal.js";
import { getFreeItemsThunk } from "../store/freeitems.js";

const opetion2 = [
  { value: "Credit Card Type", label: "Online Payment" },
  // { value: "in-store", label: "In Store" },
];

const opetion1 = [
  { value: "Credit Card Type", label: "Online Payment" },
]

const MenuList = () => {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(opetion2[0]); // Initialize with the default value
  const restaurant = useSelector((state) => state.restaurant.restaurant);
  const [selectedTipPercentage, setSelectedTipPercentage] = useState(15); // Default tip percentage
  const [customTipAmount, setCustomTipAmount] = useState(0); // State to track custom tip amount
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); 
  const { setTipAmount, language, changedCart, setChangedCart, restaurantId, setRestaurantId} = useContext(Context)
  const [name , setName] = useState('')
  const [phone , setPhone] = useState('')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(false);
  const restaurantStatus = useSelector((state) => state.restaurant.onlineStatus);
  const [needDelivery, setNeedDelivery] = useState(false);
  const [addressVerified, setAddressVerified] = useState(false);
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    // Create a new Date object representing the current date and time in the system's local time zone
    const today = new Date();
    const adjustedToday = new Date(today.getTime() - today.getTimezoneOffset() * 60000); // Adjust for local time zone
    
    return adjustedToday; // Return the adjusted date
    
    
  });  
  const [timeSlots, setTimeSlots] = useState(getTimeSlots(selectedDate));
  const [selectedTime, setSelectedTime] = useState('');
  const [needSchedule, setNeedSchedule] = useState(false);
  
  const [code, setCode] = useState('');
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const coupon = useSelector((state) => state.coupons.coupon);
  const [freeItem, setFreeItem] = useState(null);
  const freeItems = useSelector((state) => state.freeitems.freeitems);
  const [editFreeItemModal, setEditFreeItemModal] = useState(false);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [addressErrors, setAddressErrors] = useState(null);
  const [showChooseRestaurant, setShowChooseRestaurant] = useState(false);

  const navigate = useNavigate()

  useEffect(() => { 
    if(!restaurantId){
      setShowChooseRestaurant(true)
    }
  }, [restaurantId])

  useEffect(() => {
    //needchange
    dispatch(getOnlineStatusThunk(restaurantId));
    dispatch(getFreeItemsThunk(restaurantId))
}, [dispatch])

useEffect(() => { 
  if(needDelivery){ 
    handleScriptLoad()
    handleStoreMap()
  }else { 
    handleStoreMap()
  }
},[needDelivery, currentUserAddress, restaurantId])



useEffect(() => { 
  const cart = JSON.parse(localStorage.getItem(`cart${restaurantId}`));
  const freeItem = JSON.parse(localStorage.getItem(`freeItem${restaurantId}`));
  
  if(cart){
    setCartItems(cart);
  }
  
  setFreeItem(freeItem);

}, [changedCart])
  
  
 
  //set tip amount for gobal state
  useEffect(() => {
    setTipAmount(calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount);
  }, [selectedTipPercentage, cartItems, setTipAmount]);

  const handleEditCartModal = (item) => { 
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen(restaurantId)){
      showTimeError(restaurantId);   
      return 
    }
    setSelectedItem(item);
    setModal(true);
  }


  const handleContinueCheckout = () => {
    if(restaurantStatus?.stop_order){ 
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }


    
    
    if(!name) return setError('Name is required')
    if(!phone) return setError('Phone is required')
    if(!phone.match(/^\d{10}$/)) return setError('Phone number is invalid')

    setError(null)
    setActiveTab(true)
  }

  const goBack = () => {
    setActiveTab(false)
  }
 
  // Function to handle click on custom tip option
  const handleCustomTipClick = () => {
    setSelectedTipPercentage(0);
    setShowCustomModal(true);
  };

  const handleIncrease = () => {
    setCustomTipAmount(prevAmount => prevAmount + 1);
  };

  const handleDecrease = () => {
    if (customTipAmount > 0) {
      setCustomTipAmount(prevAmount => prevAmount - 1);
    }
  };

  // Function to handle click on predefined tip options
  const handleTipOptionClick = (tipPercentage) => {
    setCustomTipAmount(0); // Reset custom tip amount
    setSelectedTipPercentage(tipPercentage);
  };

  // Function to handle submission of custom tip in modal
  const handleCustomTipSubmit = () => {
    setShowCustomModal(false);
  };

  const handlePaymentMethodChange = (selectedPaymentMethod) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
  };

  const handlePayInStore = async () => {
    setLoading(true);
    try {
        await dispatch(placeOrderThunkPayInStore(
          restaurant?.id, 
          selectedPaymentMethod.value, 
          name, 
          phone, 
          calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount,
           'Pickup', 
           cartItems,
           needSchedule ? convertTime(selectedDate, selectedTime) : null,
           coupon?.coupon_id || null,
           freeItem?.Item ? freeItem : null
          ))


        .then(() => localStorage.removeItem(`cart${restaurantId}`))

      setLoading(false);
      // Redirect the user to the order confirmed page
      // Navigate to the order confirmed page with query parameters
      navigate(`/order-confirmed/1/'tzdsar'/4215/023/Pickup/${restaurantId}`);
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle error if necessary
      // Set loading to false in case of error
      setLoading(false);
    } finally {
      // Disconnect from the Socket.IO server when the component unmounts
      // This is important to prevent memory leaks
      setLoading(false);
    }
  };

  const handleNeedSchedule = () => {
    setNeedSchedule(true);
    setShowPickupModal(true);
  }
  

  const calculateTotal = (cartItems) => {
    //calcuate all items total with attribute
     let subtotal = cartItems.reduce(
       (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
       0
     );
 
     cartItems.forEach((cartItem) => {
       cartItem.options.forEach((option) => {
         subtotal += option.priceModifier * cartItem.quantity;
       });
     });
 
 
     const tax = subtotal * (restaurant?.Restaurant_Fees[0]?.tax || 0);
     let service_fee = subtotal * (restaurant?.Restaurant_Fees[0]?.service_fee || 0);
     const convenienceFee = parseFloat(
       restaurant?.Restaurant_Fees[0]?.commission_fee || 0
     );
     let total = subtotal + tax + convenienceFee 
 
     const deliveryFee = restaurant?.Restaurant_Fees[0]?.delivery_fee || 0;
 
     if(needDelivery){
       total += +deliveryFee;
       service_fee += +convenienceFee
     }
 
 
     return {
       subtotal: subtotal.toFixed(2),
       tax: tax.toFixed(2),
       convenienceFee: convenienceFee.toFixed(2),
       total: total.toFixed(2),
       deliveryFee: deliveryFee,
       service_fee: service_fee.toFixed(2)
     };
   };
 
   const calculateTotalWithTip = (cartItems, tipPercentage) => {
     let subtotal = cartItems.reduce(
       (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
       0
     );
 
     cartItems.forEach((cartItem) => {
       cartItem.options.forEach((option) => {
         subtotal += option.priceModifier * cartItem.quantity;
       });
     });
 
     
     const tax = subtotal * (restaurant?.Restaurant_Fees[0]?.tax || 0);
     const service_fee = subtotal * (restaurant?.Restaurant_Fees[0]?.service_fee || 0);
     const convenienceFee = parseFloat(
       restaurant?.Restaurant_Fees[0]?.commission_fee || 0
     );
     const deliveryFee = restaurant?.Restaurant_Fees[0]?.delivery_fee || 0;
 
     // Calculate tip amount based on percentage or custom
     const tipAmount = customTipAmount
     ? customTipAmount
     : subtotal * (tipPercentage / 100);
 
     let total = subtotal + tax + convenienceFee + tipAmount;
     
     if(needDelivery){
       total += +deliveryFee + service_fee;
     }
     
 
     return {
       tipAmount: tipAmount.toFixed(2),
       total: total.toFixed(2),
       subtotal: subtotal.toFixed(2),
     };
   };

  const handleDeleteItem = (itemId) => {
    if(restaurantStatus?.stop_order){
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen(restaurantId)){
      showTimeError(restaurantId);
    }

    const localCart = JSON.parse(localStorage.getItem(`cart${restaurantId}`));
    const updatedCart = localCart.filter(item => item.Item.id !== itemId);
    setCartItems(updatedCart);
    localStorage.setItem(`cart${restaurantId}`, JSON.stringify(updatedCart));
    setChangedCart(!changedCart);
    
  };

  const handleProceedToPayment = () => {
    if(restaurantStatus?.stop_order){
      Swal.fire({
        icon: 'error',
        title: 'Restaurant Too Busy',
        text: 'Online order is currently closed!',
      })
      return;
    }

    if(!isStoreOpen(restaurantId)){
      showTimeError(restaurantId);
      return
    }

    if(!needAmountReached()){ 
      Swal.fire({
        icon: 'error',
        title: 'Free Item Minimum Amount Not Reached',
        text: 'You have not reached the minimum amount to redeem this item, please add more items to your cart to reach the minimum amount!',
      })
      return;
    }

    const iflunchitem = cartItems.filter(item => item.Item.name.toLowerCase().includes('lunch'))

    if(!checkIfLunchTime() && iflunchitem.length > 0){
      Swal.fire({
        icon: 'error',
        title: 'Lunch Time Error',
        text: 'You have lunch items in your cart, lunch items are only available right now!',
      })
      return;
    }

    setShowPaymentModal(true);
  }
  
  function getItemAttributeNameById(id, item) {
    // Iterate over Restaurant_Item_Attributes array
    for (const attribute of item.Restaurant_Item_Attributes) {
      // Iterate over Item_Attribute_Options array within each Item_Attribute
      for (const option of attribute.Item_Attribute.Item_Attribute_Options) {
        // Check if the id matches
        if (option.id === id) {
          return option.name; // Return the name if id matches
        }
      }
    }
    // Return null if no matching id is found
    return null;
  }

  const calculateItemTotal = (item) => {
    let total = item?.Item?.price * item?.quantity;
    item?.options?.forEach((option) => {
      total += option.priceModifier * item?.quantity;
    });
    return total.toFixed(2);
  }



  function getTimeSlots(date) {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    
    let startHour = currentHour;
    let startMinute = 0;

    // Check if the selected date is today
    const isToday = date.getDate() === currentTime.getDate() && date.getMonth() === currentTime.getMonth() && date.getFullYear() === currentTime.getFullYear();
    
    // If the selected date is today, set the starting point for time slots
    if (isToday) {
        // Determine starting time based on the current minute
        if (currentMinute >= 15) {
            startMinute = 15;
            startHour += 1;  // Start from the next hour
        } else if (currentMinute < 15) {
            startMinute = 30;
        }
    } else {
        // If the selected date is not today, start from 12:00 AM
        startHour = 0;
        startMinute = 0;
    }
    
    const timeSlots = [];
    
    // Generate time slots from the starting time until midnight
    for (let hour = startHour; hour < 24; hour++) {
        for (let minute = startMinute; minute < 60; minute += 30) {
            const time = new Date(date);
            time.setHours(hour);
            time.setMinutes(minute);
            // Add the formatted time string to the timeSlots array
            timeSlots.push(
                time.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                })
            );
            // Reset minute to 0 for subsequent hours
            startMinute = 0;
        }
    }
    
    return timeSlots;
}
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleConfirm = () => {
    setShowPickupModal(false);
    setNeedSchedule(true);

  };
  

  // Function to handle date selection
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setTimeSlots(getTimeSlots(date)); // Pass the selected date to getTimeSlots
  };

  // Function to get one week's dates
  const getOneWeekDates = () => {
    const today = new Date();
    const dates = [];
    const adjustedToday = new Date(today.getTime() - today.getTimezoneOffset() * 60000); // Adjust for local time zone


    for (let i = 0; i < 7; i++) {
        const nextDay = new Date(adjustedToday);
        nextDay.setDate(today.getDate() + i);
        const day = nextDay.getDate();
        const dayIndex = nextDay.getDay();

        const month = nextDay.toLocaleString('default', { month: 'short' });
        const weekday = nextDay.toLocaleString('default', { weekday: 'short' });

        // Determine the label for the day
        let dayLabel = weekday; // Default label is the weekday abbreviation
        if (i === 0) {
            dayLabel = 'Today';
        }

        dates.push({
             day: dayLabel,
            date: `${month} ${day}`,
            dayIndex: dayIndex,
            fullDate: nextDay,
        });
    }
    return dates;
};
  const convertTime = (date, time) => {
    return date.toISOString().split('T')[0] + ' ' + time

  };


  let autocomplete;
  const handleScriptLoad = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  function handlePlaceSelect() {
    let place = autocomplete.getPlace();

    if(!place.geometry){ 
      setAddressErrors('Please enter a valid address')
      
      document.getElementById("autocomplete").value = "";

      return;
    }else { 
      
      const res = checkDistance(place.formatted_address)
      res.then((result) => {
        if(result){
          setCurrentUserAddress(place.formatted_address);
          setAddressVerified(true);
          setAddressErrors('');
        }
      })
    }
  }
  const checkDistance = async (userAddress) => {
    const geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: userAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const userLocation = results[0].geometry.location;
          const restaurantLocation = new google.maps.LatLng(restaurantId === 7 ? 35.1587 : 35.1369603 , restaurantId === 7 ? -89.793788 : -89.9909528);
  
          // Calculate distance between user's location and restaurant's location
          const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(userLocation, restaurantLocation);
          const distanceInMiles = distanceInMeters * 0.000621371; // Convert meters to miles
  
          // Log distances for debugging
    
  
          // Check if the distance is within 5 miles
          if (distanceInMiles <= 4) {
            // Address is within 5 miles, proceed with delivery
            setAddressErrors('');
            setAddressVerified(true);
            resolve(true);
          } else {
            // Address is more than 5 miles away
            setAddressErrors('Sorry, we cannot deliver to places more than 5 miles away.');
            setAddressVerified(false);
            resolve(false);
          }
        } else {
          // Geocoding failed, show an error
          setAddressErrors('Unable to geocode the address. Please try again later.');
          setAddressVerified(false);
          resolve(false);
        }
      });
    });
  };
  

  const handleStoreMap = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    const mapOptions = {
      center: new google.maps.LatLng(restaurantId === 7 ? 35.1587 : 35.1369603 , restaurantId === 7 ? -89.793788 : -89.9909528),
      zoom: needDelivery ? 10 : 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new google.maps.Map(document.getElementById("gmp-map"), mapOptions);
  
    // Add marker
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(restaurantId === 7 ? 35.1587 : 35.1369603 , restaurantId === 7 ? -89.793788 : -89.9909528),
      title: "Petals Of Peony",
      map: map
    });
  
    if (needDelivery) {
      // Add circle overlay
      const circle = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(restaurantId === 7 ? 35.1587 : 35.1369603 , restaurantId === 7 ? -89.793788 : -89.9909528),
        radius: 8046.72 // 5 miles in meters
      });
    }
  };

    const checkCurrentCoupon = async (e) => {
      e.preventDefault();
      setCheckingCoupon(true);
      try { 
           await dispatch(checkCouponThunk(code));
      } catch (error) {
          setCheckingCoupon(false);
         
      } finally {
          setCheckingCoupon(false);
      }
  }
    
  const calculateDiscount = (total, discount, discountType, require) => {
    if(+require < +total){
      if(discountType === 'Percentage'){
        return (total * +discount / 100);
      }else if (discountType === 'Amount'){
        return +discount
      }
    }else {
      return 0;
    }
  }
  

  const handleRemoveCoupon = () => {
    setCode('');
    dispatch(clearCoupon(''));
  }

  const handleEditFreeItem = (item) => {
    setSelectedItem(item);
    setEditFreeItemModal(true);
  }
  
  const handleRemoveFreeItem = () => {
    localStorage.setItem(`freeItem${restaurantId}`, null);
    setFreeItem(null);
  }

  const needAmountReached = () => {
    // Iterate through freeItems array
    const freeItemed = JSON.parse(localStorage.getItem(`freeItem${restaurantId}`));

    if(freeItemed?.Item){
    for (const freeItem of freeItems) {
      // Find the matching ReedemableItem based on item 
      for(const item of freeItem.ReedemableItems){ 
        if(item?.Item?.id === freeItemed?.Item?.id){
          return +freeItem.need_amount <= +calculateTotal(cartItems).subtotal
        }
      }
    }}else { 
      return true
    }
  }

  const handleToOrder = (id) => {
    setShowChooseRestaurant(false);
    setRestaurantId(id)
    setChangedCart(!changedCart);
  }


  return (
    <>
     {showChooseRestaurant && ( 
            <Modal show={showChooseRestaurant} centered >
            <Modal.Header >
              <Modal.Title>Please Choose a location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="bg-white text-dark py-5">
      <div className="container">
        <h2 className="text-center mb-4">Our Locations</h2>
        <div className="row justify-content-center ">
          <div className="col-md-5 mb-4 ">
            <div className="  custom-location-border  ">
              <div className="card-body text-center  mt-3">
                <h5 className="card-title p-3 ">Cordova Location</h5>
                <p className="card-text mt-2">
                  1250 N Germantown Pkwy
                  <br />
                  Cordova, TN 38016
                  <br />
                  (901)-707-8886
                </p>
                <button
                  onClick={() => handleToOrder(7)}
                  className="btn btn-primary mb-3"
                >
                  Order Here
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-5 mb-4">
            <div className=" custom-location-border">
              <div className="card-body text-center mt-3">
                <h5 className="card-title p-3">Overton Square Location</h5>
                <p className="card-text mt-2">
                  2110 Madison Ave,
                  <br />
                  Memphis, TN 38104
                  <br />
                  (901)-207-6680
                </p>
                <button
                  onClick={() => handleToOrder(11)}
                  className="btn btn-primary mb-3"
                >
                  Order Here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
            </Modal.Body>
          </Modal>
    )} 
      <Modal show={showPickupModal} onHide={() => setShowPickupModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Schedule Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mb-2">
              <div className="col">
                <div className="date-row">
                  <div className="scroll-view">
                    {getOneWeekDates().map((dayData, index) => (
                      <div
                      className={`date-box ${dayData.fullDate.getDate() === selectedDate.getDate() ? 'selected border-3 border-dark' : ' '}`}

                       key={index} onClick={() => handleDateSelect(dayData.fullDate)}>
                        <div className={`day ${dayData.fullDate.getDate() === selectedDate.getDate() ? 'selected' : ''}`}>
                          {dayData.day}
                        </div>
                        <div className={`date ${dayData.fullDate.getDate() === selectedDate.getDate() ? 'selected' : ''}`}>
                          {dayData.date}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ maxHeight: '300px', overflow: 'auto' }}>
                <div className="time-ranges">
                  {timeSlots.map((timeSlot, index) => (
                    <div
                      key={index}
                      className={`row time-slot-box ${selectedTime === timeSlot ? 'selected border-dark border-3' : 'border-dark border-bottom'}`}
                      style={{ cursor: 'pointer', fontWeight: '600'}}
                      onClick={() => handleTimeSelect(timeSlot)}
                    >
                      <div className="col border-dark p-3">{timeSlot}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary w-100" onClick={handleConfirm} disabled={!selectedTime}>
            Confirm
          </button>
          <button className="btn btn-white w-100" onClick={() => setShowPickupModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>



   <div className="py-5 mt-5">
    <div className="row justify-content-center align-items-center">
      <div className="col" style={{height:'100%'}}>
        <div className="card">
          <div className="card-body p-0">
            <div className="row">
              <Link to="/online-order" className="text-decoration-none text-dark">
                <h5 style={{ cursor: 'pointer' }}>
                  <i className="fas fa-long-arrow-alt-left me-2"></i> Continue shopping
                </h5>
              </Link>

              <hr />
              <div className="col-lg-7 col-xxl-7 col-md-12 overflow-auto" >
                {/* Apply maxHeight and overflow-auto */}
                
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4 className="">Checkout Detail</h4>
                    <div className="d-flex w-50">
                      <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${!needDelivery ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setNeedDelivery(false)}
                          >
                            Pickup
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${needDelivery ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => setNeedDelivery(true)}
                          >
                            Delivery
                            </div>

                          
                      </div>
                  
                </div>


                <div style={{ width: '100%', height: '250px' }} id="gmp-map"></div>

                <div>
                <div className="d-flex  justify-content-between align-items-center" >
                  <div>
                {needDelivery && (
                  <p className="text-danger mt-2">
                    *We deliver within 5 miles of our location*
                  </p>
                )}
                <i className="fas fa-house me-2">
                </i>
                <Link>
                {restaurantId && restaurantId === 7 ? '1250 N Germantown Pkwy, Cordova, TN 38016' : '2110 Madison Ave, Memphis, TN 38104'} 
                </Link>
                 <span className="text-danger ms-2 text-decoration-underline" onClick={() => setShowChooseRestaurant(true)}>Change Store</span>

                  </div>
                  </div>

                <div>

                  {needDelivery && (
                <div className="d-flex  justify-content-between align-items-center mt-1" >

                  <div className="d-flex align-items-center">
                    <i className="fas fa-map-marker-alt me-2">
                    </i>
                    {currentUserAddress ? ( 
                      <div className="d-flex align-items-center ">
                      <Link>
                      {currentUserAddress}
                      </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column">
                      {addressErrors && (
                        <p className="text-danger">{addressErrors}</p>
                        )}
                      <input
                        id="autocomplete"
                        placeholder="Enter your address"
                        style={{ width: '350px', borderRadius:'5px', padding:'5px'}}
                      />
                      </div>
                    )}
                    </div>
                    {currentUserAddress && (
                    <button className="btn btn-primary" onClick={() => {
                      setCurrentUserAddress(null);
                      setAddressVerified(false);
                      
                    }}>Edit</button>
                    )}
                  

                </div>
                  )}
                </div>
                </div>
                <div style={{borderBottom:'2px solid rgb(238, 238, 238)'}}  className="mt-2"/>
                
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4 className="widget-title m-0">Order Summary</h4>
                    
                </div>
                

                <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">You have {cartItems?.length} items in your cart</p>
                    <div >
                      
                        </div>
                    </div>


                    {freeItem?.Item && (
                    <div className="card mb-3" >
                    <div
                      onClick={() => handleEditFreeItem({
                        id: freeItem?.Item.id,
                        name: freeItem?.Item?.name,
                        price: freeItem?.Item.price,
                        quantity: freeItem?.quantity,
                        special_instructions: freeItem?.special_instructions,
                        image_url: freeItem?.Item?.image_url,
                        Restaurant_Item_Attributes: freeItem?.Item?.Restaurant_Item_Attributes,
                        options: freeItem?.options,
                      })}
                      className="card-body checkout-card-body bg-light p-3 m-2"
                    >
                      {/* Card content */}
                      <div className="d-flex justify-content-between">
                        {/* Left side */}
                        <div className="d-flex flex-row align-items-center">
                          <div>
                            {freeItem?.Item?.image_url && (
                              <img
                                src={freeItem?.Item?.image_url}
                                className="rounded-3"
                                style={{ width: "65px" }}
                                alt={freeItem?.Item?.name}
                              />
                            )}
                          </div>
                          <div className="ms-3">
                            <h5>{language === 'en' ? freeItem?.Item?.name : freeItem?.Item?.chinese_name}</h5>
                            {freeItem?.options?.map((attribute) => (
                              <p className="small m-0 p-0" key={attribute.id}>
                                {getItemAttributeNameById(attribute.id, freeItem.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                              </p>
                            ))}
                            {freeItem?.special_instructions && (
                              <p className="small m-0 p-0">
                                <strong>**</strong> {freeItem?.special_instructions}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* Right side */}
                        <div className="d-flex flex-row align-items-center">
                          <div style={{ width: "50px" }}>
                            <h5 className="fw-normal mb-0">{freeItem?.quantity}</h5>
                          </div>
                          <div style={{ width: "80px" }}>
                            <h5 className="mb-0">Free</h5>
                          </div>
                          <a href="#!" className="text-muted">
                            <i
                              onClick={(e) => {
                                e.stopPropagation(); // Stop event propagation
                                handleRemoveFreeItem();
                              }}
                              className="fas fa-trash-alt"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                    
                  )  
                  }
                {/* Display cart items */}
                {cartItems?.map((item) => (
                  
                  <div key={item.id} className="card mb-3" >

                    <div
                      onClick={() => handleEditCartModal({
                        id: item?.Item.id,
                        name: item?.Item?.name,
                        price: item?.Item.price,
                        quantity: item?.quantity,
                        special_instructions: item?.special_instructions,
                        image_url: item?.Item?.image_url,
                        Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                        options: item?.options,
                      })}
                      className="card-body checkout-card-body bg-light p-3 m-2"
                    
                    >
                      {/* Card content */}
                      <div className="d-flex justify-content-between" >  
                        {/* Left side */}
                        <div className="d-flex flex-row align-items-center" >
                           <div>
                            {item?.Item?.image_url && (
                              <img
                                src={item?.Item?.image_url}
                                className="rounded-3"
                                style={{ width: "65px" }}
                                alt={item?.Item?.name}
                              />
                            )}
                          </div>
                          <div className="ms-3">
                            <h5>{language === 'en' ? item?.Item?.name : item?.Item?.chinese_name}</h5>
                            {item?.options?.map((attribute) => (
                              <p className="small m-0 p-0" key={attribute.id}>
                                {getItemAttributeNameById(attribute.id, item.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                              </p>
                            ))}
                            {item?.special_instructions && (
                              <p className="small m-0 p-0">
                                <strong>**</strong> {item?.special_instructions}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* Right side */}
                        <div className="d-flex flex-row align-items-center">
                          <div style={{ width: "50px" }}>
                            <h5 className="fw-normal mb-0">{item?.quantity}</h5>
                          </div>
                          <div style={{ width: "80px" }}>
                            <h5 className="mb-0">${calculateItemTotal(item)}</h5>
                          </div>
                          <a href="#!" className="text-muted">
                            <i
                              onClick={(e) => {
                                e.stopPropagation(); // Stop event propagation
                                handleDeleteItem(item?.Item.id);
                              }}
                              className="fas fa-trash-alt"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                            }
                <div style={{borderBottom:'2px solid rgb(238, 238, 238)'}}  className="mt-2 mb-2"/>

              </div>
                
              {!cartItems?.length ? (
               <div className="col-md-5 d-flex justify-content-center align-items-center">
               <div className="col-lg-5 text-center" onClick={() => navigate('/online-order')}
               style={{cursor: 'pointer'}}
               >
                <p>Continue Shopping</p>
                 <Lottie
                   loop
                   animationData={emptyCartLottie}
                   play
                   style={{ width:'200px', height:'200px', margin: 'auto'}}
                 />
                 <p>Your Cart Is Empty</p>
               </div>
             </div>
             
              ) : (
                  <div className="col-lg-5 sticky-header">
                    <div className="shop-form widget">


                    <div className="col-lg-12 col-xxl-12 col-md-12 overflow-auto" >
                {/* Apply maxHeight and overflow-auto */}
                

                </div>

                      <h4 className="widget-title">Order Total</h4>


                    
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 text-bold">Subtotal</p>
                        <p className="mb-0">${calculateTotal(cartItems).subtotal}</p>
                      </div>

                      {coupon?.discount && 
                      coupon?.min_require < calculateTotal(cartItems).subtotal
                      &&  (
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Discount({coupon.discount} {coupon.discount_type === 'Percentage' ? '%' : '$'})</p>
                        <p className="mb-0">
                            {coupon.discount_type === 'Percentage' ?
                                `-$${(calculateTotal(cartItems).subtotal * (coupon.discount / 100)).toFixed(2)}`
                                : `-$${coupon.discount}`
                            }
                        </p>
                    </div>
                      )}

                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Tax</p>
                        <p className="mb-0">${calculateTotal(cartItems).tax}</p>
                      </div>
                      {!needDelivery && (
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Convenience Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems).convenienceFee}</p>
                      </div>
                      )}
                      {needDelivery && (
                        <>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Service Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems).service_fee}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">Delivery Fee</p>
                        <p className="mb-0">${calculateTotal(cartItems)?.deliveryFee}</p>
                      </div>
                      </>
                      )}
                      <div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-0">Tip</p>
                          <p className="mb-0">${calculateTotalWithTip(cartItems, selectedTipPercentage).tipAmount}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 10 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(10)}
                          >
                            10%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 15 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(15)}
                          >
                            15%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 18 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTipOptionClick(18)}
                          >
                            18%
                          </div>
                          <div
                            className={`border rounded p-2 w-100 text-center btn-gray ${selectedTipPercentage === 0 ? "bg-primary text-white" : ""}`}
                            style={{ cursor: "pointer" }}
                            onClick={handleCustomTipClick}
                          >
                            {customTipAmount ? `${customTipAmount}$` : "Custom"}
                          </div>
                        </div>
                      </div>
                      <hr />



                                            {/**coupon can enter here */}
                                            <div>
                        <form className="d-flex justify-content-between" onSubmit={checkCurrentCoupon}>
                        <input type="text" className="form-control" placeholder="Coupon"
                        value={code}
                        onChange={(e) => setCode(e.target.value.toUpperCase())}
                        required
                        
                        />
                        <button className="btn btn-primary" >
                          {checkingCoupon ? 'loading...' : 'Apply'}
                        </button>
                        </form>
                      </div>

                
                      {coupon?.message && (
                         <p className="text-danger">{coupon.message}</p>
                           )}

                      {coupon?.min_require > calculateTotal(cartItems).subtotal ? ( 
                        <div className="d-flex justify-content-between">
                        <p className="text-danger">A Miniunm of ${coupon?.min_require} spend is required</p>
                        <p className="mb-0" style={{textDecoration:'underline', color:'blue', cursor:'pointer'}} 
                        onClick={() => navigate('/online-order')}
                        >Continue Shopping</p>
                        {/**continue shopping*/}
                        </div>
                      ) : coupon?.discount && ( 
                        <div className="d-flex justify-content-between">
                        <p className="mt-2">Discount({coupon.discount} {coupon.discount_type === 'Percentage' ? '%' : '$'})</p>
                        <p className="mb-0">
                            {coupon.discount_type === 'Percentage' ?
                                `-$${(calculateTotal(cartItems).subtotal * (coupon.discount / 100)).toFixed(2)}`
                                : `-$${coupon.discount}`
                            }
                            <button className="btn btn-danger btn-sm" onClick={handleRemoveCoupon}>Remove</button>
                        </p>
                    </div>
                      )}


                      <hr />




                      <div className="d-flex justify-content-between">
  <p className="mb-0">Total</p>
  <div className="mb-0" style={{ fontWeight: 'bold' }}>
    {coupon?.discount && coupon?.min_require < calculateTotal(cartItems).subtotal ? (
      <>
        <span style={{ position: 'relative', display: 'inline-block' }}>
          ${calculateTotalWithTip(cartItems, selectedTipPercentage).total}
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              right: 0,
              height: '2px', // Adjust thickness here
              backgroundColor: 'red', // Adjust color here
              transform: 'translateY(-50%)',
              zIndex: 1
            }}
          />
        </span>
        <br />
        <span>
          $
          {(
            calculateTotalWithTip(cartItems, selectedTipPercentage).total -
            calculateDiscount(
              calculateTotalWithTip(cartItems, selectedTipPercentage).subtotal,
              coupon?.discount,
              coupon?.discount_type,
              coupon?.min_require
            )
          ).toFixed(2)}
        </span>
      </>
    ) : (
      <span>
        ${calculateTotalWithTip(cartItems, selectedTipPercentage).total}
      </span>
    )}
  </div>
</div>

                    

                    <p className="text-danger">
                      {needDelivery && !addressVerified ? (
                        "Please verify your address to proceed"
                      ) : null}
                    </p>
                    </div>
                    {!showPaymentModal && (
                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-hover-2 w-100 p-4"
                          type="button"
                          value="submit"
                          name="submit"
                          onClick={handleProceedToPayment}
                          disabled={!isStoreOpen(restaurantId) || restaurantStatus?.stop_order || !addressVerified && needDelivery}
                        >
                          {isStoreOpen(restaurantId) && !restaurantStatus?.stop_order ? "Proceed to Payment" : "Restaurant not open"}
                        </button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

      <Modal
  show={showPaymentModal}
  onHide={() => setShowPaymentModal(false)}
  backdropClassName="overlay"
  dialogClassName="modal-50w"
  centered
>
  {loading ? (
    <div className="d-flex justify-content-center align-items-center" >
    <Lottie
      loop
      animationData={loader}
      play
      style={{ width: "50%", height: "100%" }}
    />
  </div>
  ) : (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Adjust the width and center the modal body */}
        {/* Payment method selection */}
        {!needDelivery ? (
        <Select
          options={opetion2}
          value={selectedPaymentMethod}
          onChange={handlePaymentMethodChange}
        />
        ) : (
          <Select
          options={opetion1}
          value={selectedPaymentMethod}
          onChange={handlePaymentMethodChange}
        />
        )}

        {/* Payment action buttons */}
     
          <div>
            {error && <p className="text-danger">{error}</p>}
              <div className="row mb-2">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    style={{textAlign: 'center'}}
                    required
                    disabled={activeTab}
                    maxLength={20}
                  />
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone(10 digits, no dashes or spaces)"
                    style={{textAlign: 'center'}}
                    required
                    disabled={activeTab}
                    maxLength={10}
                  />
                </div>
                {!activeTab ? (
                  <Button  className="btn-hover-3 p-3 w-100 mt-3 btn-pimary"  onClick={handleContinueCheckout}>Continue</Button>
                  ): null }
                {activeTab && (
                  <>
                  <FontAwesomeIcon icon={faArrowLeft} onClick={goBack} style={{fontSize:'20px', padding:'5px', cursor:'pointer'}} />

                  
                  {selectedPaymentMethod.value === 'in-store' ? (
                  <Button className="btn btn-pimary btn-hover-2 w-100 p-4 mt-3" onClick={handlePayInStore}>Pay In Store</Button>
                  ): (
                <CheckoutApp 
                tip={calculateTotalWithTip(cartItems, selectedTipPercentage)?.tipAmount} 
                restaurantId={restaurant?.id} 
                name={name} 
                phone={phone} 
                cartItems={cartItems}
                coupon={coupon?.coupon_id || null}
                freeItem={freeItem?.Item ? freeItem : null}
                address={needDelivery ? currentUserAddress : 'Pickup'}
                />
                  )}
                </>
                )}
              </div>
          </div>
        
      </Modal.Body>
    </>
  )}
</Modal>


  <Modal show={showCustomModal} onHide={() => setShowCustomModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>Custom Tip</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="d-flex justify-content-center align-items-center">
        <button className="btn btn-primary btn-hover-2" onClick={handleDecrease}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <InputGroup>
          <input
            type="text"
            className="form-control text-center"
            value={customTipAmount}
            readOnly
          />
        </InputGroup>
        <button className="btn btn-primary btn-hover-2" onClick={handleIncrease}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary w-100" onClick={handleCustomTipSubmit}>
        Submit
      </button>
    </Modal.Footer>
  </Modal>
  <EditFreeItemModal modal={editFreeItemModal} setModal={setEditFreeItemModal} item={selectedItem} />
      <EditCartModal modal={modal} setModal={setModal} item={selectedItem} />
  
    </>
  );
};

const ShopCheckout = () => {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div className="container">
       
            <MenuList />
        </div>
      </div>
    </>
  );
};

export default ShopCheckout;
