import { useNavigate } from "react-router-dom";
import pop1 from '../assets/images/pop6.png'
import pop2 from '../assets/images/pop1.png'

const OurEvent = () => {
  const navigate = useNavigate();

  return (
    <section className="content-inner overflow-hidden petal-back-new" >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 image-grid">
            <img src={pop1} alt="img" />
            <img src={pop2} alt="img" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-b30 wow fadeInUp text-content">
            <h2 className=" text-center ">LET US HELP WITH ​YOUR EVE​NTS</h2>
            <p className="text-black">
            Blossom your events with the exquisite taste ​of Sichuan cuisine from Petals of a Peony. Our​ catering service adds a spicy twist to your​ celebrations, offering a symphony of flavors​ that will leave your guests craving for more.​
            </p>
            <a className="text-bold text-black" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+19017078886" 
             >Cordova:  (901)-707-8886</a>
            <br />
            <a className="text-bold text-black" style={{fontSize: '22px', textDecoration:'underline'}}
              href="tel:+19012076680" 
             >Overton: (901)-207-6680 </a>
            <br />
            <button className="btn btn-primary btn-md shadow-primary m-r30  w-100" onClick={() => navigate('/catering')}>Request Inqiure</button>
                        
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurEvent;
