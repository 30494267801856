import Swal from "sweetalert2";

export const isStoreOpen = (id) => {
  // Get the current date and time in the Eastern Time Zone

  
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
  });

  if(id === 7 && new Date(currentDate).getDay() === 2){
    return false ;
  }

  // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const currentDayOfWeek = new Date(currentDate).getDay();

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
  });

  // Define the store's opening hours for both IDs
  const openingHours = {
    7: [
      { day: 0, start: "12:00", end: "22:30" }, // Sunday
      { day: 1, start: "12:00", end: "22:30" }, // Monday
      // { day: 2, start: "12:00", end: "22:30" }, // Tuesday
      { day: 3, start: "12:00", end: "22:30" }, // Wednesday
      { day: 4, start: "12:00", end: "22:30" }, // Thursday
      { day: 5, start: "12:00", end: "22:00" }, // Friday
      { day: 6, start: "12:00", end: "22:00" }, // Saturday
    ],
    11: [
      { day: 0, start: "12:00", end: "22:30" }, // Sunday
      { day: 1, start: "12:00", end: "15:30" }, // Monday
      { day: 1, start: "17:30", end: "22:30" }, // Monday
      { day: 2, start: "12:00", end: "15:30" }, // Tuesday
      { day: 2, start: "17:30", end: "22:30" }, // Tuesday
      { day: 3, start: "12:00", end: "15:30" }, // Wednesday
      { day: 3, start: "17:30", end: "22:30" }, // Wednesday
      { day: 4, start: "12:00", end: "15:30" }, // Thursday
      { day: 4, start: "17:30", end: "22:30" }, // Thursday
      { day: 5, start: "12:00", end: "22:00" }, // Friday
      { day: 6, start: "12:00", end: "22:00" }, // Saturday
    ],
  };

  // If id is not 7 or 12, return false
  if (!openingHours[id]) {
    return false;
  }

  // Get the opening hours for the specified id
  const storeHours = openingHours[id];

  // Find the opening hours for the current day
  const openingHour = storeHours.filter((hour) => hour.day === currentDayOfWeek);

  // If openingHour is empty, it means the store is closed today
  if (openingHour.length === 0) {
    return false;
  }

  // Check if the current time is within any of the store's opening hours
  const isOpen = openingHour.some((hour) => {
    const startTime = new Date(`01/01/2022 ${hour.start}`);
    const endTime = new Date(`01/01/2022 ${hour.end}`);
    const currentTimeFormatted = new Date(`01/01/2022 ${currentTime}`);
    return currentTimeFormatted >= startTime && currentTimeFormatted <= endTime;
});

  return isOpen;
};

export const showTimeError = (id) => {
  let message = '';
  if (id === 7) {
    message = 'Hours: Mon-Sun 12:00AM-9:30PM Central Time, Tues: CLOSED.';
  } else if (id === 11) {
    message = 'Hours: Mon - Thur: 12:00AM-2:30PM, 4:30PM-9:30PM, Fri-Sat: 12:00AM-10:00PM, Sun: 12:00AM-9:30PM.';
  }

  Swal.fire({
    title: 'Please come back later.',
    text: message,
  });
};


  export const checkIfLunchTime = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Extract the current time in HH:mm format
    const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

        // Extract the current day
        const currentDay = new Date(currentDate).getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        // Check if it's a weekend (Saturday or Sunday)
        if (currentDay === 0 || currentDay === 6) {
            return false; // Return false if it's a weekend
        }
    

    // Define the store's opening hours
    const openingHours = { start: "12:00", end: "17:00" };

    // Extract hours and minutes from current time
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    // Extract hours and minutes from opening hours
    const [startHour, startMinute] = openingHours.start.split(":").map(Number);
    const [endHour, endMinute] = openingHours.end.split(":").map(Number);

    // Check if current time is within lunch hours
    const isLunchTime =
        (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute));

    return isLunchTime;
};
