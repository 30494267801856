import React from 'react';

const Loader = () => {
  const loaderStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
  };

  const spinnerStyle = {
    border: '8px solid #f3f3f3', // Light grey
    borderTop: '8px solid #d9534f', // New color
    borderRadius: '50%',
    width: '60px', // New size
    height: '60px', // New size
    animation: 'spin 2s linear infinite',
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={loaderStyle}>
        <div style={spinnerStyle}></div>
      </div>
    </>
  );
};

export default Loader;
