/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import logo from "../assets/images/logo.jpg";
import logo2 from "../assets/images/logo2.png";

import background from "../assets/images/bk.png"
import background1 from '../assets/images/background/chefhouseback.jpg'
import sushi1 from '../assets/images/background/sushi1.jpg'
import sushi2 from '../assets/images/background/sushi2.jpg'
import sushi3 from '../assets/images/background/sushi3.jpg'
import sushi4 from '../assets/images/background/sushi4.jpg'


export const IMAGES = {

  logo: logo,
  logo2: logo2,
    background: background,
    background1: background1,
    sushi1: sushi1,
    sushi2: sushi2,
    sushi3: sushi3,
    suhi4: sushi4,


}
