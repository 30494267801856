import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWaitTimeThunk, joinWaitListThunk } from '../store/waitlist';
import Header from '../components/Header';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

const WaitlistPage = ({ restaurant }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [partySize, setPartySize] = useState('');
  const currentWaitList = useSelector(state => state.waitlist.waitTime);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWaitTimeThunk(11));
  }, [dispatch]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const cleanedPhone = phone.replace(/-/g, '');
  
    // Dispatch the action and wait for its completion
    const newWaitList = await dispatch(joinWaitListThunk(11, name, cleanedPhone, partySize));
    
    // Check if newWaitList is not null or undefined
    if (newWaitList && newWaitList.id) {
      navigate(`/waitlist/${newWaitList.id}`);
    }
  
    // Reset form fields after submission
    setName('');
    setPhone('');
    setPartySize('');
  };

  return (
    <>
      <Header />
      <div className="position-relative d-flex flex-column align-items-center justify-content-center bg-dark text-light" style={{ height: '100vh', borderRadius: '0.5rem', overflow: 'hidden', marginTop:'100px'}}>
        <div className="position-relative z-1 text-center p-4" style={{ maxWidth: '40rem', margin: '0 auto' }}>
          
          
          <h1 className="text-light fw-bold" style={{ fontSize: '2rem' }}>
            Join the waitlist
          </h1>

          {currentWaitList && (
            <>
              <div>
                <span className="text-secondary">This is ONLY for location: <br />                    2110 Madison Ave Memphis, TN 38104
                    <br />
                    (901)-207-6680</span>
                <p>
                  There are currently <strong style={{ color: 'red', fontSize: '20px' }}>{currentWaitList.waitlist_counts}</strong> parties ahead of you.
                  The estimated wait time is <strong style={{ color: 'red', fontSize: '20px' }}>{currentWaitList.wait_time}</strong> minutes.
                </p>
              </div>
            </>
          )}
          <p className="text-secondary my-2" style={{ maxWidth: '20rem', margin: '0 auto' }}>
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              className="form-control mt-4 bg-dark text-secondary border-secondary rounded"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="row mt-3">
              <div className="col-md-6 mb-3">
                <label htmlFor="phone" className="form-label">Phone Number</label>
                <InputMask
                  mask="999-999-9999"
                  className="form-control bg-dark text-secondary border-secondary rounded"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="999-999-9999"
                  required
                />
                <span className="text-muted">*We'll send you notifications about your waitlist status</span>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="partySize" className="form-label">Party Size</label>
                <input
                  type="number"
                  className="form-control bg-dark text-secondary border-secondary rounded"
                  id="partySize"
                  value={partySize}
                  onChange={(e) => setPartySize(e.target.value)}
                  required
                  min={1}
                  max={50}
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100">Join Waitlist</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default WaitlistPage;
