import React, { useState, useRef } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom/dist';
import emailjs from 'emailjs-com';

const times = [
  '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM',
  '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM',
  '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM'
];

const CateringForm = () => {
    const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    people: '',
    date: dayjs(),
    time: '',
    occasion: '',
    cateringType: '',
    budget: '50',
    specialRequests: '',
    subscribe: false
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const refs = {
    name: useRef(),
    phone: useRef(),
    email: useRef(),
    people: useRef(),
    date: useRef(),
    time: useRef(),
    occasion: useRef(),
    cateringType: useRef(),
    budget: useRef()
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      subscribe: e.target.checked
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      people: formData.people,
      date: formData.date.format('MMMM D, YYYY'),
      time: formData.time,
      occasion: formData.occasion,
      cateringType: formData.cateringType,
      budget: formData.budget,
      specialRequests: formData.specialRequests
    };

    emailjs.send('service_7d03enj', 'template_ia353qo', templateParams, 'qAd3HK1wqeVSxsppX')
        .then((result) => {
            console.log(result);
        }, (error) => {
            console.log(error);
        });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    
    if (!formData.name) newErrors.name = 'Full Name is required';
    if (!formData.phone || !validatePhone(formData.phone)) newErrors.phone = 'Valid Phone is required';
    if (!formData.email || !validateEmail(formData.email)) newErrors.email = 'Valid Email is required';
    if (!formData.people) newErrors.people = 'Number of People is required';
    if (!formData.date) newErrors.date = 'Date is required';
    if (!formData.time) newErrors.time = 'Time is required';
    if (!formData.occasion) newErrors.occasion = 'Occasion is required';
    if (!formData.cateringType) newErrors.cateringType = 'Type of Catering Service is required';
    if (!formData.budget) newErrors.budget = 'Budget per Person is required';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle form submission
        sendEmail(e);
      setIsSubmitted(true);
    } else {
      const firstErrorField = Object.keys(newErrors)[0];
      refs[firstErrorField].current.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }
  };

  if (isSubmitted) {
    return (
      <>
        <Header />
        <div style={{marginTop: '80px'}}></div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor: 'background.paper',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" gutterBottom>
            Thank You!
          </Typography>
          <Typography variant="body1">
            We will contact you shortly about your inquiry.
          </Typography>
          <button onClick={() => navigate('/')} style={{marginTop: '20px'}}>Back to Home</button>
        </Box>
      </>
    );
  }

  return (
    <>
    <Header />
    <div style={{marginTop:'80px'}}></div>
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper'
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" gutterBottom>
        Catering
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please fill out the form below and we'll do our best to accommodate your desired date and any requests you may have to make your party/celebration memorable.
      </Typography>
      <TextField
        label="Full Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
        margin="normal"
        fullWidth
        error={!!errors.name}
        helperText={errors.name}
        inputRef={refs.name}
      />
       <TextField
        label="Phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        required
        margin="normal"
        fullWidth
        error={!!errors.phone}
        helperText={errors.phone}
        inputRef={refs.phone}
      />
      <FormControlLabel
        control={<Checkbox checked={formData.subscribe} onChange={handleCheckboxChange} />}
        label="I'd like to get texts about specials, events, and other exclusive offers and announcements not available to the general public."
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        margin="normal"
        fullWidth
        error={!!errors.email}
        helperText={errors.email}
        inputRef={refs.email}
      />
      <FormControl fullWidth margin="normal" error={!!errors.people}>
        <TextField
            label="Number of People"
            name="people"
            value={formData.people}
            onChange={handleChange}
            type="number"
            required
            error={!!errors.people}
            helperText={errors.people}
            inputRef={refs.people}
        />
        {!!errors.people && <FormHelperText>{errors.people}</FormHelperText>}
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    label="Select Date"
    value={formData.date}
    onChange={handleDateChange}
    minDate={dayjs()}  // This line disables dates before today
    renderInput={(params) => (
      <TextField 
        {...params} 
        fullWidth 
        margin="normal" 
        required 
        error={!!errors.date} 
        helperText={errors.date}
        inputRef={refs.date}
      />
    )}
  />
</LocalizationProvider>
      <FormControl fullWidth margin="normal" error={!!errors.time}>
        <InputLabel>Select Time</InputLabel>
        <Select
          name="time"
          value={formData.time}
          onChange={handleChange}
          required
          inputRef={refs.time}
        >
          {times.map((time, index) => (
            <MenuItem key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
        {!!errors.time && <FormHelperText>{errors.time}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth margin="normal" error={!!errors.occasion}>
        <InputLabel>Occasion</InputLabel>
        <Select
          name="occasion"
          value={formData.occasion}
          onChange={handleChange}
          required
          inputRef={refs.occasion}
        >
          <MenuItem value="Birthday">Birthday</MenuItem>
          <MenuItem value="Wedding">Wedding</MenuItem>
          <MenuItem value="Corporate Event">Corporate Event</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
        {!!errors.occasion && <FormHelperText>{errors.occasion}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth margin="normal" error={!!errors.cateringType}>
        <InputLabel>Type Of Catering Service</InputLabel>
        <Select
          name="cateringType"
          value={formData.cateringType}
          onChange={handleChange}
          required
          inputRef={refs.cateringType}
        >
          <MenuItem value="Buffet">Buffet</MenuItem>
          <MenuItem value="Plated">Plated</MenuItem>
          <MenuItem value="Family Style">Family Style</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
        {!!errors.cateringType && <FormHelperText>{errors.cateringType}</FormHelperText>}
      </FormControl>
      <TextField
        label="Budget per Person"
        name="budget"
        value={formData.budget}
        onChange={handleChange}
        type="number"
        required
        margin="normal"
        fullWidth
        error={!!errors.budget}
        helperText={errors.budget}
        inputRef={refs.budget}
      />
      <TextField
        label="Description / Requests (optional)"
        name="specialRequests"
        value={formData.specialRequests}
        onChange={handleChange}
        margin="normal"
        multiline
        rows={4}
        fullWidth
      /> 
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Box>
    </>
  );
};

export default CateringForm;
