import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { createCheckoutSessionThunk } from "../store/stripe";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeCheckoutForm";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
// const stripePromise = loadStripe("pk_test_51N1jsSIzDtG53Sp0X5LEbfj3PLSnsTabqURdXKBEbHqHbr4gG48TacEO4aFcWTd6t4BOFjv2uQaJDjUVpijOjns700cXVFzGvo", { 
//     stripeAccount: 'acct_1P836YE7h93oQjBP'

// });
const store1stripePromise = loadStripe("pk_live_51N1jsSIzDtG53Sp0IYroac8Yq8aSbX7PQgISERDGaQNviBMnz4SGJ4RhjZws64QrELl0aJoWqtyYe5W3Ud6bqhmV00sR5crhK1", { 
  stripeAccount:'acct_1PKs5bAquJ4dh7My' 
});

const store2stripePromise = loadStripe("pk_live_51N1jsSIzDtG53Sp0IYroac8Yq8aSbX7PQgISERDGaQNviBMnz4SGJ4RhjZws64QrELl0aJoWqtyYe5W3Ud6bqhmV00sR5crhK1", {
  stripeAccount:'acct_1PVGoVALN5kw7y7Y'
});



const CheckoutApp = ({tip, restaurantId, name, phone, cartItems, coupon, freeItem, address}) => {
  const dispatch = useDispatch();
  const clientSecret = useSelector((state) => state?.stripe?.data?.clientSecret);

  useEffect(() => {
    dispatch(createCheckoutSessionThunk(tip, restaurantId, cartItems, coupon, address))

  }, [dispatch]);

  const appearance = {
    theme: 'flat',
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <div >
      {clientSecret && (
        <Elements options={options} stripe={restaurantId === 11 ? store2stripePromise : store1stripePromise}>
          <CheckoutForm tip={tip} name={name} phone={phone} cartItems={cartItems} coupon={coupon} freeItem={freeItem} address={address} restaurantId={restaurantId}/>
        </Elements>
      )}
    </div>
  );
}

export default CheckoutApp;
