import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../context/AppContext';

const EditFreeItemModal = ({ item, modal, setModal }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [special_instructions, setSpecialInstructions] = useState('');
  const [alertMessages, setAlertMessages] = useState({});
  const [disabledAddToCart, setDisabledAddToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { changedCart, setChangedCart, restaurantId} = useContext(Context)

  useEffect(() => {
    if (modal && item) {
      // Prefill selected options if the item already has some selected
      if (item?.options) {
        const optionsToAdd = item.options.flatMap((attribute) =>
          ({
            id: attribute.id,
            priceModifier: attribute.priceModifier,
            attributeId: attribute.attributeId,
          })
        );
        setSelectedOptions(optionsToAdd);
      }


      if (item.special_instructions) {
        setSpecialInstructions(item.special_instructions);
      } else {
        setSpecialInstructions('');
      }

      // Find the corresponding item in the restaurant

    }
  }, [modal, item]);

  useEffect(() => {
    const checkRequiredOptions = () => {
      let allOptionsSelected = true;
      let alerts = {};

      item?.Restaurant_Item_Attributes.forEach((attribute) => {
        const requiredOptions = attribute?.Item_Attribute?.requ || 0;
        const selectedOptionsCount = selectedOptions.filter(opt => opt.attributeId === attribute.id).length;

        if (requiredOptions > selectedOptionsCount || requiredOptions !== selectedOptionsCount && requiredOptions !== 0) {
          allOptionsSelected = false;
          alerts[attribute.id] = `*Must Select ${requiredOptions} options and you selected ${selectedOptionsCount}`;
        } else {
          alerts[attribute.id] = '';
        }
      });

      setAlertMessages(alerts);
      setDisabledAddToCart(!allOptionsSelected);
    };

    checkRequiredOptions();
  }, [selectedOptions, item]);

  useEffect(() => {
    if (!modal) {
      // Reset state when modal is closed
      setSelectedOptions([]);
      setSpecialInstructions('');
      setAlertMessages({});
      setDisabledAddToCart(false);
    }
  }, [modal]);


 
  const handleOptionChange = (optionId, priceModifier, attributeId) => {
    const existingOption = selectedOptions.find((opt) => opt.id === optionId);

    if (existingOption) {
      // If the option is already selected, remove it
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((opt) => opt.id !== optionId)
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { id: optionId, priceModifier, attributeId },
      ]);
    }
  };





  // Handle add item to cart
  const handleUpdateItem = () => {
    setIsLoading(true);

    const updatedItem = {
      Item: item,
      special_instructions,
      options: selectedOptions,
      price: 0.00,
    };

    //update item in localstorage
    localStorage.setItem(`freeItem${restaurantId}`, JSON.stringify(updatedItem));

    setChangedCart(!changedCart);
    setIsLoading(false);
    setModal(false);

  }

  // Handle remove item from cart
  const handleRemoveItem = () => {
    setIsDeleting(true);
    //delete item from localstorage, only one item so no need to map 
    localStorage.setItem(`freeItem${restaurantId}`, null);

    setIsDeleting(false);
    setModal(false);
    setChangedCart(!changedCart);
  };
  


  return (
    <>   
     <Modal show={modal} onHide={() => setModal(false)} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{item?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          {item?.image_url && (
            <img
              src={item?.image_url}
              alt={item?.name}
              className="img-fluid mb-3"
              style={{ width: '100%', maxHeight: '220px', objectFit: 'cover' }}
            />
          )}
          <p>{item?.description?.length > 1 ? item?.description : item?.name}</p>
          {/* Attributes Section */}
          {item?.Restaurant_Item_Attributes && item?.Restaurant_Item_Attributes?.length > 0 && (
            <div class="w-100">
<h6>Options:</h6>
              <Form>
                {item?.Restaurant_Item_Attributes?.map((attribute) => (
                  <Form.Group key={attribute.id} className="mb-3">
                    <Form.Label className="mb-2">{attribute?.Item_Attribute?.name} <br />
                      <small className="text-danger">{alertMessages[attribute.id]}</small>
                    </Form.Label>

                    <div className="d-flex flex-wrap">
                      {attribute?.Item_Attribute?.Item_Attribute_Options?.map((option) => (
                        <div className='checkbox-wrapper-47 m-0' key={option.id}>
                          <input
                            type="checkbox"
                            id={option.name}
                            name={option.name}
                            value={option.id}
                            onChange={() => handleOptionChange(option.id, option.price_modifier, attribute.id)}
                            checked={selectedOptions.some((opt) => opt.id === option.id)}
                          />
                          <label htmlFor={option.name}>{option.name} {option?.price_modifier > 0 ? `+ $${option.price_modifier}` : null}</label>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                ))}
              </Form>
            </div>
          )}
          {/* Special Instructions */}
          <Form.Group className="w-100 mb-3">
            <Form.Label>Special Instructions:</Form.Label>
            <Form.Control value={special_instructions} onChange={(e) => setSpecialInstructions(e.target.value)} type="text" placeholder="Add special instructions..." />
          </Form.Group>
          {/* Quantity choose */}
     
        </div>
        {/* Additional content, such as special instructions, can be added here */}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between', alignItems: 'center' }}>
  <div style={{ flex: '1', textAlign: 'right' }}>
    <Button variant="danger" onClick={handleRemoveItem} disabled={isDeleting}>
      {isDeleting ? <span className='spinner-border'></span> : `Remove`}
    </Button>
    <span className='mx-2'></span>
    <Button variant="success" onClick={handleUpdateItem} disabled={disabledAddToCart}>
      {isLoading ? <span className='spinner-border'></span> : `Update       ($0.00)`}
    </Button>
    
  </div>
</Modal.Footer>
    </Modal>
    </>

  );
};

export default EditFreeItemModal;
